var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      [
        _c(
          "div",
          { ref: "cocorretagem", staticClass: "box-corretor" },
          [
            _c("div", { staticClass: "px-8 mb-10" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "vx-col w-1/5" },
                [
                  _c("vs-input", {
                    staticClass: "mt-2",
                    attrs: {
                      name: "codigoQuadroDistribuicao",
                      size: "large",
                      maxlength: "10",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.preencherCodigoQuadroDistribuicao(
                          $event,
                          "codigoQuadroDistribuicao"
                        )
                      },
                    },
                    model: {
                      value: _vm.codigoQuadroDistribuicao,
                      callback: function ($$v) {
                        _vm.codigoQuadroDistribuicao = $$v
                      },
                      expression: "codigoQuadroDistribuicao",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._l(_vm.cocorretagens, function (corretor, index) {
              return _c("div", { key: index, staticClass: "px-8" }, [
                _c("p", { staticClass: "mb-1" }, [
                  _c("strong", [_vm._v(_vm._s(corretor.corretorCount))]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-row mt-0 mb-0 box-corretor-cocorretor" },
                  [
                    _c("div", { staticClass: "vx-col w-11/12" }, [
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-8/12" },
                          [
                            _vm._m(1, true),
                            _c(
                              "multiselect",
                              {
                                staticClass: "box-cocorretor-razao-social",
                                attrs: {
                                  disabled: index === 0,
                                  value: corretor.razaoSocial,
                                  options: _vm.listaCorretores,
                                  searchable: true,
                                  loading: _vm.isLoading,
                                  "internal-search": false,
                                  "clear-on-select": true,
                                  "options-limit": 300,
                                  limit: 3,
                                  "max-height": 600,
                                  placeholder:
                                    "Digite o CNPJ ou a razão social",
                                  "show-no-options": false,
                                  "show-no-results": true,
                                  "hide-selected": true,
                                  "open-direction": "bottom",
                                },
                                on: {
                                  "search-change": _vm.debounceSearchBroker,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "option__desc",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addNameCnpjCorretor(
                                                    index,
                                                    props.option
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "option__title",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "option-razao-social",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            props.option
                                                              .BrokerName
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                  props.option.BrokerDocument
                                                    ? _c(
                                                        "b",
                                                        { staticClass: "mt-3" },
                                                        [
                                                          _c("the-mask", {
                                                            staticClass:
                                                              "w-full the-mask option-cnpj",
                                                            attrs: {
                                                              mask: [
                                                                "##.###.###/####-##",
                                                              ],
                                                              disabled: "true",
                                                              masked: true,
                                                              value:
                                                                props.option
                                                                  .BrokerDocument,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  props.option.Locations[0]
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "option-address",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                props.option
                                                                  .Locations[0]
                                                                  .Address
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "noResult",
                                      fn: function (props) {
                                        return _c("span", {}, [
                                          _c(
                                            "p",
                                            { staticClass: "option-cnpj" },
                                            [_vm._v(_vm._s(props.search))]
                                          ),
                                          _c(
                                            "b",
                                            { staticClass: "option-no-result" },
                                            [_vm._v("Corretor não cadastrado!")]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "option-no-result" },
                                            [
                                              _vm._v(
                                                "Solicite cadastro do mesmo"
                                              ),
                                            ]
                                          ),
                                        ])
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("template", {
                                  slot: "clear",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (props) {
                                          return _c("div", {
                                            staticClass: "multiselect__clear",
                                            on: {
                                              mousedown: function ($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.limparListaCocorretores(
                                                  props.search
                                                )
                                              },
                                            },
                                          })
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-4/12" },
                          [
                            _vm._m(2, true),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["###.##", "##.##", "#.##"],
                                  expression: "['###.##', '##.##', '#.##']",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: { name: "percentRate", size: "large" },
                              on: {
                                blur: function ($event) {
                                  return _vm.valorPercentualMudou(
                                    $event.target.name,
                                    index
                                  )
                                },
                              },
                              model: {
                                value: corretor.Rate,
                                callback: function ($$v) {
                                  _vm.$set(corretor, "Rate", $$v)
                                },
                                expression: "corretor.Rate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    !corretor.Leader
                      ? _c(
                          "div",
                          {
                            staticClass: "trash-icon-mapfre",
                            on: {
                              click: function ($event) {
                                return _vm.deleteCorretorCocorretagem(
                                  corretor.id
                                )
                              },
                            },
                          },
                          [_c("i", { staticClass: "onpoint-trash icon-font" })]
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            }),
            _c("div", { staticClass: "footer-right" }, [
              _c(
                "div",
                {
                  staticClass: "adicionar-cocorretor",
                  on: { click: _vm.addCorretorCocorretagem },
                },
                [
                  _c("span", { staticClass: "adicionar-cocorretor-text" }, [
                    _vm._v("Adicionar outro cocorretor"),
                  ]),
                  _vm._m(3),
                ]
              ),
            ]),
          ],
          2
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Código do quadro de distribuição")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Razão social")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("% participação na cocorretagem")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "adicionar-cocorretor-plus-button" }, [
      _c("i", { staticClass: "onpoint-plus icon-font" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }