<template>
  <div>
    <vs-button
      v-if="changed"
      color="warning"
      type="border"
      class="btn-view-changes"
      @click="showModalAlteracoes = true"
    >
      <i class="onpoint-clock-counter-clockwise icon-font pr-3"></i> Ver alterações
    </vs-button>

    <vs-popup
      title="Alterações da proposta"
      :active.sync="showModalAlteracoes"
      class="alteracao-modal"
    >
      <div v-for="(item, index) in alteracoes" :key="index">
        <vs-row>
          <vs-col vs-lg="12" vs-sm="12">
            <p class="w-full">
              <span class="font-semibold">Data da alteração: </span
              >{{ $utils.dateToDDMMYYYYHHmm(item.createDate) }}
            </p>
            <p class="w-full">
              <span class="font-semibold">Alterado por: </span>{{ item.author }}
            </p>
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col vs-lg="12" vs-sm="12">
            <div class="container-changed w-full">
              <span
                v-for="(itemDiff, index) in item.diffProposalRisk"
                :key="index"
              >
                <p class="w-full font-semibold">
                  {{ itemDiff.label }}
                </p>

                <p
                  class="w-full font-semibold text-primary pb-5"
                  v-html="itemDiff.value"
                />
              </span>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <vs-row>
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          v-if="showDesfazer"
        >
          <vs-button type="border" class="btn" @click="showModalConfirm = true">
            <i class="onpoint-arrow-counter-clockwise icon-font pr-3"></i>
            Reverter alterações
          </vs-button>
        </vs-col>
      </vs-row>

      <vs-popup
        class="popup-confirm"
        title="Deseja realmente desfazer às alterações?"
        :active.sync="showModalConfirm"
      >
        <div class="vw-row mt-3">
          <div class="vx-col w-full text-right">
            <vs-button
              @click="showModalConfirm = false"
              class="button-default mr-5"
            >
              Cancelar
            </vs-button>
            <vs-button @click="desfazerAlteracao()" class="button-primary">
              Confirmar
            </vs-button>
          </div>
        </div>
      </vs-popup>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "visualizar-alteracoes",
  props: {
    proposalUniqueId: { type: String, require: true },
    showDesfazer: { type: Boolean, default: true },
    currentValues: { type: Object, require: true }
  },
  data() {
    return {
      showModalAlteracoes: false,
      showModalConfirm: false,
      alteracoes: []
    };
  },
  watch: {
    async currentValues(value) {
      await this.loadGetProposalRiskChange();
    }
  },
  computed: {
    changed() {
      return this.alteracoes.length > 1;
    }
  },
  methods: {
    ...mapActions("proposta-module", [
      "reseteChanges",
      "getProposalRiskChange"
    ]),

    async loadGetProposalRiskChange() {
      await this.getProposalRiskChange(this.proposalUniqueId).then(response => {
        this.alteracoes = [];

        response.forEach(item => {
          this.alteracoes.push({
            author: item.Author,
            createDate: item.CreateDate,
            diffProposalRisk: [
              {
                label: "Prêmio",
                value: item.InsurancePremium.toLocaleString(
                  "pt-BR",
                  this.$utils.moneyFormatOptions
                )
              },
              {
                label: "% Comissão",
                value: item.ComissionPercentage.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  currency: "BRL"
                })
              },
              {
                label: "Valor da comissão",
                value: item.ComissionValue.toLocaleString(
                  "pt-BR",
                  this.$utils.moneyFormatOptions
                )
              },
              {
                label: "Taxa aplicada(%)",
                value: item.Tax.toLocaleString("pt-BR", {
                  minimumFractionDigits: 6,
                  currency: "BRL"
                })
              }
            ]
          });
        });
      });
    },

    async desfazerAlteracao() {
      await this.$onpoint.loading(async () => {
        return await this.reseteChanges(this.proposalUniqueId).then(response => {
          this.showModalConfirm = false;
          this.showModalAlteracoes = false;

          this.$emit("reseteChanges", response);
        });
      });
    }
  },
  async mounted() {
    await this.loadGetProposalRiskChange();
  }
};
</script>

<style lang="scss">
.btn-view-changes {
  background: rgba(var(--vs-warning), 0.08) !important;
  height: fit-content;
  padding: 5px 10px !important;
  font-size: 12px;

  &:hover {
    background: rgba(var(--vs-warning), 0.2) !important;
  }
}

.con-vs-popup {
  .popup-confirm {
    z-index: auto;

    .vs-popup {
      width: 900px !important;
      height: 90%;
    }
  }
}

.alteracao-modal {
  .vs-popup {
    padding: 0px !important;

    .vs-popup--content {
      overflow-y: auto;
    }
  }
}

.container-changed {
  display: block;
  background: #f8f8f8;
  border-radius: 6px;
  padding: 15px;
}

@media (max-width: 900px) {
  .container-btn-changes {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &:first-of-type {
      padding-top: 5px;
      order: 2;
    }
    &:last-of-type {
      padding-bottom: 5px;
      order: 1;
    }
  }
}
</style>

<style lang="scss" scoped>
.icon-font::before{
  position: relative;
  top: 2px;
}
</style>