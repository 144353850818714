<template>
  <div>
    <template>
      <div class="box-corretor" ref="cocorretagem">
        <div class="px-8 mb-10">
          <p>
            <b>Código do quadro de distribuição</b>
          </p>

          <div class="vx-col w-1/5">
            <vs-input
              class="mt-2"
              name="codigoQuadroDistribuicao"
              size="large"
              maxlength="10"
              v-model="codigoQuadroDistribuicao"
              @input="preencherCodigoQuadroDistribuicao($event, 'codigoQuadroDistribuicao')"
            />
          </div>
        </div>

        <div
          class="px-8"
          v-for="(corretor, index) in cocorretagens"
          :key="index"
        >

          <p class="mb-1">
            <strong>{{ corretor.corretorCount }}</strong>
          </p>

          <div class="vx-row mt-0 mb-0 box-corretor-cocorretor">
            <div class="vx-col w-11/12">
              <div class="vx-row">
                <div class="vx-col w-8/12">
                  <p>
                    <b>Razão social</b>
                  </p>
                  <multiselect
                    class="box-cocorretor-razao-social"
                    :disabled="index === 0"
                    :value="corretor.razaoSocial"
                    :options="listaCorretores"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :max-height="600"
                    placeholder="Digite o CNPJ ou a razão social"
                    :show-no-options="false"
                    :show-no-results="true"
                    :hide-selected="true"
                    open-direction="bottom"
                    @search-change="debounceSearchBroker"
                  >
                    <template slot="option" slot-scope="props">
                      <div
                        class="option__desc"
                        @click="addNameCnpjCorretor(index, props.option)"
                      >
                        <span class="option__title">
                          <p class="option-razao-social">
                            {{ props.option.BrokerName }}
                          </p>
                          <b class="mt-3" v-if="props.option.BrokerDocument">
                            <the-mask
                              class="w-full the-mask option-cnpj"
                              :mask="['##.###.###/####-##']"
                              disabled="true"
                              :masked="true"
                              :value="props.option.BrokerDocument"
                            />
                          </b>
                          <p
                            class="option-address"
                            v-if="props.option.Locations[0]"
                          >
                            {{ props.option.Locations[0].Address }}
                          </p>
                        </span>
                      </div>
                    </template>
                    <template slot="clear">
                      <div
                        class="multiselect__clear"
                        slot-scope="props"
                        @mousedown.prevent.stop="
                          limparListaCocorretores(props.search)
                        "
                      ></div>
                    </template>
                    <span slot="noResult" slot-scope="props">
                      <p class="option-cnpj">{{ props.search }}</p>
                      <b class="option-no-result">Corretor não cadastrado!</b>
                      <p class="option-no-result">Solicite cadastro do mesmo</p>
                    </span>
                  </multiselect>
                </div>
                <div class="vx-col w-4/12">
                  <p>
                    <b>% participação na cocorretagem</b>
                  </p>
                  <vs-input
                    v-mask="['###.##', '##.##', '#.##']"
                    class="w-full"
                    name="percentRate"
                    size="large"
                    v-model="corretor.Rate"
                    @blur="valorPercentualMudou($event.target.name, index)"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="!corretor.Leader"
              class="trash-icon-mapfre"
              @click="deleteCorretorCocorretagem(corretor.id)"
            >
              <i class="onpoint-trash icon-font"></i>
            </div>
          </div>
        </div>
        <div class="footer-right">
          <div class="adicionar-cocorretor" @click="addCorretorCocorretagem">
            <span class="adicionar-cocorretor-text">Adicionar outro cocorretor</span>
            <div class="adicionar-cocorretor-plus-button">
              <i class="onpoint-plus icon-font"></i>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import { mask, TheMask } from "vue-the-mask";

export default {
  name: "cocorretagem",
  directives: { mask },
  components: {
    Multiselect,
    TheMask
  },
  data() {
    return {
      cocorretor: {
        AmountValue: 0,
        CoBrokerId: "",
        CompanyId: "",
        Leader: false,
        ProposalId: "",
        Rate: "0.00",
        CreateByPersonId: "",
        Id: "",
        UniqueId: "",
        UpdateByPersonId: "",

        id: 0,
        corretorCount: "",
        razaoSocial: "",
        cnpj: ""
      },
      listaCorretores: [],
      cocorretagens: [],
      isLoading: false,
      codigoQuadroDistribuicao: ""
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["cocorretagem", "proposta"])
  },
  mounted() {
    this.codigoQuadroDistribuicao =
      this.proposta.CoBrokers.length > 0
        ? this.proposta.CoBrokers[0].CodigoQuadroDistribuicao
        : "";
    this.init();
  },
  destroyed() {
    this.limparCocorretores();
  },
  methods: {
    ...mapMutations("proposta-module", ["clearCocorretagem"]),
    ...mapActions("corretor-module", ["searchCorretores"]),
    ...mapActions("proposta-module", ["adicionaCocorretagem"]),

    debounceSearchBroker: _.debounce(function(searchString) {
      this.limparListaCocorretores();
      if (searchString && searchString.length > 3) {
        this.isLoading = true;
        this.searchCorretores(searchString)
          .then(respCorretores => {
            if (respCorretores.length > 0) {
              this.listaCorretores = respCorretores.map(corretor => {
                return {
                  ...corretor,
                  Locations: corretor.Locations.filter(
                    locations => locations.IsMain === true
                  )
                };
              });
            }
            this.isLoading = false;
          })
          .catch(err => {});
      } else {
        return;
      }
    }, 500),

    limparCocorretores() {
      this.clearCocorretagem();
    },

    limparListaCocorretores() {
      this.listaCorretores.length = 0;
    },

    buscaCorretorLider() {
      const CodigoQuadroDistribuicao = this.codigoQuadroDistribuicao
        ? this.codigoQuadroDistribuicao
        : "";
      this.cocorretagens.push({
        AmountValue: "",
        CoBrokerId: this.proposta.Broker.Id,
        CodigoQuadroDistribuicao,
        CompanyId: this.proposta.Broker.CompanyId,
        CreateByPersonId: this.proposta.Broker.CreateByPersonId,
        Id: this.proposta.Broker.Id,
        Leader: true,
        ProposalId: this.proposta.UniqueId,
        Rate: "100.00",
        UniqueId: this.proposta.Broker.UniqueId,
        UpdateByPersonId: this.proposta.Broker.UpdateByPersonId,

        id: 0,
        corretorCount: "Corretor Líder",
        razaoSocial: this.proposta.Broker.Name,
        cnpj: this.proposta.Broker.CpfCnpj
      });
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },

    buscaCocorretagens(CoBrokers) {
      CoBrokers.map(corretores => {
        const Rate = corretores.Rate.toFixed(2);
        const CodigoQuadroDistribuicao = this.codigoQuadroDistribuicao
          ? this.codigoQuadroDistribuicao
          : "";
        this.cocorretagens.push({
          AmountValue: corretores.AmountValue,
          CoBrokerId: corretores.CoBrokerDetail.Id,
          CodigoQuadroDistribuicao,
          CompanyId: corretores.CompanyId ? corretores.CompanyId : "",
          CreateByPersonId: corretores.CreateByPersonId
            ? corretores.CreateByPersonId
            : "",
          Id: corretores.Id ? corretores.Id : "",
          Leader: corretores.Leader ? corretores.Leader : false,
          ProposalId: corretores.ProposalId ? corretores.ProposalId : "",
          Rate: Rate ? Rate : "00.00",
          UniqueId: corretores.UniqueId ? corretores.UniqueId : "",
          UpdateByPersonId: corretores.UpdateByPersonId
            ? corretores.UpdateByPersonId
            : "",

          corretorCount: corretores[0] ? "Corretor Líder" : "Corretor",
          razaoSocial: corretores.CoBrokerDetail.Name,
          cnpj: corretores.CoBrokerDetail.CpfCnpj
        });
      });
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },

    addCorretorCocorretagem() {
      this.cocorretor.id++;
      this.cocorretor.corretorCount = "Corretor";
      this.cocorretor.CodigoQuadroDistribuicao = this.codigoQuadroDistribuicao;
      this.cocorretor.ProposalId = this.proposta.UniqueId;
      this.cocorretagens.push(Object.assign({}, this.cocorretor));
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },

    addNameCnpjCorretor(CoBrokerId, objCorretor) {
      this.cocorretagens[CoBrokerId].razaoSocial = objCorretor.BrokerName;
      this.cocorretagens[CoBrokerId].cnpj = objCorretor.BrokerDocument;
      this.cocorretagens[CoBrokerId].CoBrokerId = objCorretor.BrokerId;
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },

    deleteCorretorCocorretagem(id) {
      this.cocorretagens = Object.assign(
        [],
        this.cocorretagens.filter(corretor => corretor.id !== id)
      );
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },

    validarNumeros(evento, caminho) {
      const valoresNumericos = evento.replace(/\D/g, "");

      let target = this;
      const parts = caminho.split(".");
      for (let i = 0; i < parts.length - 1; i++) {
        target = target[parts[i]];
      }
      target[parts[parts.length - 1]] = valoresNumericos;
    },

    preencherCodigoQuadroDistribuicao(event, caminho) {
      this.validarNumeros(event,caminho)

      this.cocorretagens.map(cocorretor => {
        cocorretor.CodigoQuadroDistribuicao = this.codigoQuadroDistribuicao;
      });
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },

    valorPercentualMudou(inputName, id) {
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },

    addStoreCocorretagem(cocorretagem) {
      this.adicionaCocorretagem(this.$utils.deepClone(cocorretagem));
    },

    init() {
      this.proposta.CoBrokers.length > 1
        ? this.buscaCocorretagens(this.proposta.CoBrokers)
        : this.buscaCorretorLider()
    }
  }
};
</script>

<style lang="scss" scoped>
.box-corretor {
  background: #f8f8f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0px;
  padding: 10px;
  &-cocorretor {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.footer-right {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.trash-icon-mapfre {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: rgba(var(--vs-primary), 1) !important;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  margin-left: 2rem;
  cursor: pointer;
}

.adicionar-cocorretor {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &-text{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: right;
    color: rgba(var(--vs-primary), 1) !important;
    margin: 0 5px 0 0;
  }

  &-plus-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: rgba(var(--vs-primary), 1) !important;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
  }
}

.icon-font::before {
  display: inline-block;
  position: relative;
  width: 16px;
  font-size: 16px !important;
}

.px-8 {
  margin: 15px 0;
}

.vx-row {
  margin: 15px -1rem;
}

.option-razao-social {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 16px;
  color: rgba(var(--vs-primary), 1) !important;
  margin: 10px 0;
}

.option-cnpj {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: #636363;
  margin-bottom: 5px;
}

.option-no-result {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: #636363;
  margin-top: 5px;
}

.option-address {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: #636363;
}

.multiselect--disabled .multiselect__select {
  background: transparent !important;
}

.multiselect--disabled {
  background: transparent !important;
}

.box-cocorretor-razao-social{
  .multiselect__content-wrapper .multiselect__element{
    span,
    span::after {
      color: #636363 !important;
      background-color: #fff !important;
    }
  }
}
</style>
