<template>
  <div>
    <template>
      <div class="box-corretor" ref="cocorretagem">
        <div
          class="px-8"
          v-for="(corretor, index) in cocorretagens"
          :key="index"
        >
          <b class="mb-15">{{ corretor.corretorCount }} </b>
          <div class="vx-row">
            <div class="vx-col w-8/12">
              <p>
                <b>Razão social</b>
              </p>
              <multiselect
                :disabled="index === 0"
                :value="corretor.razaoSocial"
                :options="corretores"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :clear-on-select="true"
                :options-limit="300"
                :limit="3"
                :max-height="600"
                placeholder="Digite o CNPJ ou a razão social"
                :show-no-options="false"
                :show-no-results="true"
                :hide-selected="true"
                open-direction="bottom"
                @search-change="debounceSearchBroker"
              >
                <template slot="option" slot-scope="props">
                  <div
                    class="option__desc"
                    @click="addNameCnpjCorretor(index, props.option)"
                  >
                    <span class="option__title">
                      <p class="option-razao-social">
                        {{ props.option.BrokerName }}
                      </p>
                      <b class="mt-3" v-if="props.option.BrokerDocument">
                        <the-mask
                          class="w-full the-mask option-cnpj"
                          :mask="['##.###.###/####-##']"
                          disabled="true"
                          :masked="true"
                          :value="props.option.BrokerDocument"
                        />
                      </b>
                      <p
                        class="option-address"
                        v-if="props.option.Locations[0]"
                      >
                        {{ props.option.Locations[0].Address }}
                      </p>
                    </span>
                  </div>
                </template>
                <template slot="clear">
                  <div
                    class="multiselect__clear"
                    slot-scope="props"
                    @mousedown.prevent.stop="clearAllBrokers(props.search)"
                  ></div>
                </template>
                <span slot="noResult" slot-scope="props">
                  <p class="option-cnpj">{{ props.search }}</p>
                  <b class="option-no-result">Corretor não cadastrado!</b>
                  <p class="option-no-result">Solicite cadastro do mesmo</p>
                </span>
              </multiselect>
            </div>
            <div class="vx-col w-2/12">
              <p>
                <b>Valor da comissão</b>
              </p>
              <currency-input
                class="w-full vs-inputx vs-input--input large hasValue"
                size="large"
                name="valorRateio"
                v-model="corretor.AmountValue"
                @blur="valueIsChanged($event.target.name, index)"
              />
            </div>
            <div class="vx-col w-2/12">
              <p>
                <b>% de rateio</b>
              </p>
              <vs-input
                v-mask="['###.##', '##.##', '#.##']"
                class="w-full"
                name="percentRate"
                size="large"
                v-model="corretor.Rate"
                @blur="valueIsChanged($event.target.name, index)"
              />
              <div
                class="trash-icon"
                v-if="!corretor.Leader"
                @click="deleteCorretorCocorretagem(corretor.id)"
              >
                <i class="onpoint-trash icon-font"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-right">
          <span class="adicionar-cocorretor">Adicionar outro corretor</span>
          <div class="plus_button" @click="addCorretorCocorretagem">
            <i class="onpoint-plus icon-font"></i>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import { mask, TheMask } from "vue-the-mask";

export default {
  name: "cocorretagem",
  directives: { mask },
  components: {
    Multiselect,
    TheMask
  },
  data() {
    return {
      dadosBuscaCorretor: [],
      proposalCoBrokers: [],
      isLoading: false,
      cnpjSearchCorretor: "",
      totalComissionValue: 0,
      campos: {
        CoBrokerId: "",
        cnpj: "",
        razaoSocial: "",
        AmountValue: 0,
        Rate: "0.00",
        id: 0,
        corretorCount: 1,
        changedValue: false,
        Leader: false
      },
      cocorretagens: [],
      corretores: [],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["cocorretagem", "proposta"])
  },
  watch: {
    "proposta.ComissionValue"(newComissaoValue) {
      this.totalComissionValue = newComissaoValue;
      this.reloadComissoes();
    }
  },
  mounted() {
    this.totalComissionValue = this.proposta.ComissionValue;
    !this.proposta.CoBrokers.length
      ? this.buscaCorretorLider()
      : this.buscaCocorretagens(this.proposta.CoBrokers);
  },
  destroyed() {
    this.claearCocorretagens();
  },
  methods: {
    ...mapMutations("proposta-module", ["clearCocorretagem"]),
    ...mapActions("corretor-module", ["searchCorretores"]),
    ...mapActions("proposta-module", ["adicionaCocorretagem"]),
    claearCocorretagens() {
      this.clearCocorretagem();
    },
    reloadComissoes() {
      this.cocorretagens.map(corretor => {
        corretor.AmountValue = (this.totalComissionValue * corretor.Rate) / 100;
      });
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },
    debounceSearchBroker: _.debounce(function(searchString) {
      this.clearAllBrokers();
      if (searchString && searchString.length > 3) {
        this.isLoading = true;
        this.searchCorretores(searchString)
          .then(respCorretores => {
            if (respCorretores.length > 0) {
              this.corretores = respCorretores.map(corretor => {
                return {
                  ...corretor,
                  Locations: corretor.Locations.filter(
                    locations => locations.IsMain === true
                  )
                };
              });
            }
            this.isLoading = false;
          })
          .catch(err => {});
      } else {
        return;
      }
    }, 500),
    clearAllBrokers() {
      this.corretores.length = 0;
    },
    addNameCnpjCorretor(CoBrokerId, objCorretor) {
      this.cocorretagens[CoBrokerId].razaoSocial = objCorretor.BrokerName;
      this.cocorretagens[CoBrokerId].cnpj = objCorretor.BrokerDocument;
      this.cocorretagens[CoBrokerId].CoBrokerId = objCorretor.BrokerId;
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },
    addStoreCocorretagem(cocorretagem) {
      if (Object.keys(cocorretagem).length === 0) {
        this.adicionaCocorretagem(newCocorretagens);
        return;
      }
      const newCocorretagens = cocorretagem.filter((corretor, index) => {
        if (this.proposta.ProposalTypeId == 2) {
          if (parseFloat(corretor.Rate) > 100) {
            this.cocorretagens[index].Rate = 0;
            this.cocorretagens[index].AmountValue = 0;
            this.$onpoint.errorModal(
              "O percentual do Rateio não pode ultrapassar 100%"
            );
          }
        } else {
          if (
            parseFloat(corretor.Rate) > 100 ||
            parseFloat(corretor.AmountValue) < 0
          ) {
            this.cocorretagens[index].Rate = 0;
            this.cocorretagens[index].AmountValue = 0;
            this.$onpoint.errorModal(
              "O percentual do Rateio não pode ultrapassar 100%"
            );
          }
        }
        // if (
        //   parseFloat(corretor.Rate) > 100 ||
        //   parseFloat(corretor.AmountValue) < 0
        // ) {
        //   this.cocorretagens[index].Rate = 0;
        //   this.cocorretagens[index].AmountValue = 0;
        //   this.$onpoint.errorModal(
        //     "O percentual do Rateio não pode ultrapassar 100%"
        //   );
        // }
        return (
          corretor.razaoSocial &&
          parseFloat(corretor.Rate) !== 0 &&
          corretor.AmountValue > 0
        );
      });

      if (JSON.stringify(newCocorretagens) === JSON.stringify(cocorretagem)) {
        this.adicionaCocorretagem(this.$utils.deepClone(newCocorretagens));
      }
    },
    valueIsChanged(inputName, id) {
      let newTotal = 0;
      let isNoChanged = 0;
      let total = 100;
      let restPercentTotal = 0;
      let restValueTotal = 0;
      this.cocorretagens[id].changedValue = true;
      this.cocorretagens.map(corretor => {
        if (corretor.changedValue) {
          if (inputName === "percentRate") {
            newTotal += parseFloat(corretor.Rate);
            const amount = (this.totalComissionValue * corretor.Rate) / 100;
            corretor.AmountValue = parseFloat(amount.toFixed(2));
          } else {
            newTotal += parseFloat(corretor.AmountValue);
            const percentBroker =
              (100 * corretor.AmountValue) / this.totalComissionValue;

            corretor.Rate = parseFloat(percentBroker.toFixed(2));
            total = this.totalComissionValue;
          }
        } else {
          isNoChanged++;
        }
      });
      if (isNoChanged === 0) {
        this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
        return;
      }

      if (inputName === "percentRate") {
        restPercentTotal = (100 - newTotal) / isNoChanged;
        restValueTotal = (this.totalComissionValue * restPercentTotal) / 100;
      } else {
        restValueTotal = (this.totalComissionValue - newTotal) / isNoChanged;

        restPercentTotal = (100 * restValueTotal) / this.totalComissionValue;
      }

      this.cocorretagens.map(corretor => {
        if (!corretor.changedValue) {
          corretor.Rate = restPercentTotal.toFixed(2);
          corretor.AmountValue = parseFloat(restValueTotal.toFixed(2));
        }
      });
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },
    addCorretorCocorretagem() {
      this.campos.id++;
      this.campos.corretorCount = "Cocorretagem";
      this.cocorretagens.push(Object.assign({}, this.campos));
    },
    deleteCorretorCocorretagem(id) {
      this.cocorretagens = Object.assign(
        [],
        this.cocorretagens.filter(corretor => corretor.id !== id)
      );
      const hasCocorretagem = this.cocorretagens.length <= 1 ? false : true;
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    },
    buscaCorretorLider() {
      this.cocorretagens.push({
        CoBrokerId: this.proposta.Broker.Id,
        cnpj: this.proposta.Broker.CpfCnpj,
        razaoSocial: this.proposta.Broker.Name,
        AmountValue: this.totalComissionValue,
        Rate: "100.00",
        id: 0,
        Leader: true,
        corretorCount: "Corretor Líder",
        changedValue: false
      });
    },
    buscaCocorretagens(CoBrokers) {
      CoBrokers.map(corretores => {
        const Rate = corretores.Rate.toFixed(2);
        this.cocorretagens.push({
          CoBrokerId: corretores.CoBrokerDetail.Id,
          cnpj: corretores.CoBrokerDetail.CpfCnpj,
          razaoSocial: corretores.CoBrokerDetail.Name,
          AmountValue: corretores.AmountValue,
          Rate,
          id: corretores.CoBrokerDetail.Id,
          Leader: corretores.Leader,
          corretorCount: "Cocorretagem",
          changedValue: true
        });
      });
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretagens));
    }
  }
};
</script>
<style lang="scss">
.box-corretor {
  background: #f8f8f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0px;
  padding: 10px;
}
.footer-right {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.trash-icon {
  color: #0f6eb4;
  cursor: pointer;
  position: relative;
  bottom: 2rem;
  left: 105%;
}

.adicionar-cocorretor {
  font-style: normal;
  font-weight: 600;
  font-size: 16.8427px;
  line-height: 21px;
  text-align: right;
  color: #005e86;
  margin: 0 15px;
}
.plus_button {
  i {
    background: #005e86;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
}
.px-8 {
  margin: 15px 0;
}

.vx-row {
  margin: 15px -1rem;
}
.option-razao-social {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 16px;
  color: #678d9a;
  margin: 10px 0;
}
.option-cnpj {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: #636363;
  margin-bottom: 5px;
}

.option-no-result {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: #8e8e8e;
  margin-top: 5px;
}

.option-address {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: #636363;
}

.multiselect--disabled .multiselect__select {
  background: transparent !important;
}
.multiselect--disabled {
  background: transparent !important;
}
</style>


<style lang="scss" scoped>
.icon-font::before {
  font-size: 16px !important;
  margin: 10px;
  position: relative;
  right: 1px;
  top: 1px;
}
</style>